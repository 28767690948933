@import url('static/css/components/preview-image/preaview-image.css');
@import url('static/css/components/preview-slider/preview-slider.css');

body {
  font-family: 'Nova Square', cursive;
}

.App {
  text-align: center;
  font-family: 'Nova Square', cursive;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  background-color: #333;
}

.navbar .navbar-brand {
  color: #07ccf7 !important;
  text-decoration: none;
}

.navbar .navbar-brand strong {
  color: #33e5b5 !important;
}

.navbar-brand {
  line-height: 45px;
}

.header {
  min-height: 80vh;
  background-color: #40eca0;
  position: relative;
}

.hader-drop {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 10vh;
  width: 100%;
  background-image: linear-gradient(
  to bottom,
  rgba(51, 51, 51, 0) 0%,
  rgba(51, 51, 51, .95) 90%,
  rgba(51, 51, 51, 1) 100%
  );

}

.pipes {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  filter: grayscale(1);
}

#root {
  background-color: #333;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.full-width {
  position: relative;
  width: 100%;
}

.hero-text-wrapper {
  position: relative;
  padding: 2rem 0 2rem 1rem;
}

.hero-text-content {
  display: block;
  position: relative;
  z-index: 110;
}

.hero-text-wrapper-back-drop{
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #06CDF6;
  opacity: .8;
  z-index: 100;
}

.white {
  color: #fff;
}

.dark {
  color: #333;
}

.blue {
  color: #07ccf7;
}

.teal-underline {
  text-decoration: underline;
  text-decoration-color: #33e5b5;
}

.teal-border {
  border-top: 1px solid rgba(51, 229, 181, .2);
}

.blue-border {
  border-top: 1px solid rgba(0, 205, 246, .2);
}

.logo-appendix {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#06cdf6+8,1bd9d7+24,30e6b5+42,3feda2+60,51f489+79,51f489+79,66ff69+100,05abe0+100 */
  background: #06cdf6; /* Old browsers */
  background: -moz-linear-gradient(left, rgba(0, 205, 246, .8) 8%, #1bd9d7 24%, #30e6b5 42%, #3feda2 60%, #51f489 79%, #51f489 79%, #66ff69 100%, #05abe0 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left,  rgba(0, 205, 246, .8) 8%,#1bd9d7 24%,#30e6b5 42%,#3feda2 60%,#51f489 79%,#51f489 79%,#66ff69 100%,#05abe0 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right,  rgba(0, 205, 246, .8) 8%,#1bd9d7 24%,#30e6b5 42%,#3feda2 60%,#51f489 79%,#51f489 79%,#66ff69 100%,#05abe0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#06cdf6', endColorstr='#05abe0',GradientType=1 ); /* IE6-9 */
}

.logo-appendix img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rendering-desc {
  padding-top: 3rem;
  background-color: #333;
  text-align: center;
}

.rendering-desc h2 {
  margin-bottom: 3rem;
}

.team {
  padding-top: 3rem;
  background-color: #333;
}

.team h2 {
  text-align: center;
}

.team .team-part {
  padding-top: 3rem;
  text-align: center;
}

.team h3 {
  padding-bottom: 1.5rem;
}

.team-part p {
  color: white;
}

.team .engineers h3 {
  color: #06cdf6;
}

.team .programmers h3 {
  color: #40eca2;
}

.team .consultant h3 {
  color: #68fe69;
}

.legacy {
  padding: 5px;
  background: black;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.legacy a {
  color: #06cdf6
}

