.rendering-control-text {
    color: #fff;
}

.MuiSlider-thumb {
    color: #07ccf7;
}

.MuiSlider-rail {
    color: #33e5b5;
}

.MuiSlider-track {
    color: #07ccf7;
}

.MuiSlider-valueLabel span span {
    color: #333;
}