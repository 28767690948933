.graph-rendering {
    position: relative;
}

.monitor-top {
    position: absolute;
    width: 100%;
    height: 20vh;
    transform: translateY(-2vh);
}

.monitor-top img, .monitor-bottom img {
    object-fit: contain;
    max-width: 100%;
}